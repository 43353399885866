import React from "react"
import styled, { css } from "styled-components"
// import { IconContext } from "react-icons"
// import { TbPhoneCall } from "react-icons/tb"
import oldphone from "../../../images/oldphone.svg"
import { media } from "../../../styles/media"
import { title, contactInfo } from "../data.yaml"

const StyledContactInfoBlock = styled.div`
  margin-block: 64px;
  font-size: 16px;
  h3 {
    // font-size: 1.5em;
    font-size: 2em;
    ${() =>
      media.sp(css`
        font-size: 1.4em;
      `)}
  }
  .border {
    border: 2px solid #dadada;
    margin-block: 16px;
    margin-block-start: 8px;
    ${() =>
      media.sp(css`
        border-width: 1px;
      `)}
  }

  .dep-name {
    font-size: 1.1em;
    font-weight: bold;
  }
`

const StyledPhoneText = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  font-size: 1.8rem;
  font-weight: bold;
  padding-block: 8px;
  margin-block: 8px;
  ${() =>
    media.sp(css`
      font-size: 1.4rem;
    `)}
`

const StyledTimeText = styled.p`
  font-size: 1.1rem;
  display: flex;
  // margin-inline-start: 32px;
  gap: 8px;
  ${() =>
    media.sp(css`
      font-size: 1rem;
    `)}
`

export default function ContactInfo() {
  return (
    <StyledContactInfoBlock>
      <h3>{title}</h3>
      <div className="border" />
      <p className="dep-name">{contactInfo.org}</p>
      <StyledPhoneText>
        {/* <IconContext.Provider value={{ style: { width: 24, height: 32 } }}>
          <TbPhoneCall />
        </IconContext.Provider> */}
        <div style={{ width: 32, height: 32 }}>
          <img src={oldphone} alt="old phone" />
        </div>
        <p>
          <a href="tel:0925150124">{contactInfo.phone}</a>
        </p>
      </StyledPhoneText>
      <StyledTimeText>
        <span>{contactInfo.timeTitle}</span>/<span>{contactInfo.time}</span>
      </StyledTimeText>
    </StyledContactInfoBlock>
  )
}
