import { graphql, useStaticQuery } from "gatsby"

export default function useQuery() {
  return useStaticQuery(
    graphql`
      query {
        francoisLogo: file(relativePath: { eq: "francoisLogoNonBorder.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        nbkLogo: file(relativePath: { eq: "nbkLogo.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        menshokuLogo: file(relativePath: { eq: "menshokuLogoAlt.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        recruitBanner: file(relativePath: { eq: "recruit_banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        nightFrancoisImage: file(
          relativePath: { eq: "nightFrancoisImageHero.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
}
