import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../../styles/media"

export default function Container({ children }) {
  return <StyledContainer>{children}</StyledContainer>
}

const StyledContainer = styled.div`
  padding: 50px 15%;
  ${() =>
    media.tablet(css`
      padding: 50px 5%;
    `)}
`
