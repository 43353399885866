import React from "react"
import styled from "styled-components"
import { useQuery } from "../hooks"
import Container from "./Container"
import ContactInfo from "./ContactInfo"
import CompanyName from "./CompanyName"
import { ExternalLinks } from "./ExternalLinks/index.js"
const StyledCompanyNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 16px;
`

export default function PageContent() {
  const { francoisLogo, nbkLogo, menshokuLogo, nightFrancoisImage } = useQuery()
  return (
    <Container>
      <StyledCompanyNameContainer>
        <CompanyName
          imgSrc={francoisLogo.childImageSharp.fluid}
          imgAlt="Logo of Francois"
          name="株式会社フランソア"
        />
        <CompanyName
          imgSrc={nbkLogo.childImageSharp.fluid}
          imgAlt="Logo of Newing Bakery Kyushu"
          name="株式会社ニューイングベーカリー九州"
        />
        <CompanyName
          imgSrc={menshokuLogo.childImageSharp.fluid}
          imgAlt="Logo of Menshoku"
          name="株式会社めん食"
          width={120}
        />
      </StyledCompanyNameContainer>
      <ContactInfo />
      <ExternalLinks />
    </Container>
  )
}
