import React from "react"
import GatsbyImage from "gatsby-image"
import styled, { css } from "styled-components"

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const StyledImageContainer = styled.div`
  width: 120px;
`

const StyledName = styled.p`
  font-size: 1.32em;
`
export default function CompanyName({
  imgSrc,
  imgAlt,
  name,
  width = 80,
  height = "auto",
}) {
  return (
    <StyledContainer>
      <StyledImageContainer>
        <GatsbyImage
          fluid={imgSrc}
          alt={imgAlt}
          style={{ width, height, objectFit: "contain", margin: "0 auto" }}
        />
      </StyledImageContainer>

      <StyledName>{name}</StyledName>
    </StyledContainer>
  )
}
