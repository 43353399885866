import React from "react"
import styled, { css } from "styled-components"

const StyledContainer = styled.div(
  ({ isLink }) => css`
    position: relative;
    max-width: 300px;
    width: 100%;
    color: #fff;
    background: #1358a0;
    background: linear-gradient(90deg, #1358a0, #15205d);
    // padding: 8px 0;
    transition: 0.3s;
    &:hover {
      ${isLink &&
      css`
        opacity: 0.8;
      `}
    }
  `
)

const labelCss = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 11px 22px;
  width: 100%;
  line-height: 1.2;
`

const StyledParagraph = styled.p`
  // display: flex;
  // flex-flow: column nowrap;
  // align-items: center;
  // padding: 11px 22px;
  // line-height: 1.2;
  ${labelCss}
`

const StyledLink = styled.a`
  ${labelCss}
`

export default function Label({ text1, text2, url, isLink = false }) {
  if (isLink) {
    return (
      <StyledContainer isLink={isLink}>
        <StyledLink href={url} target="_blank" rel="noreferrer noopener">
          <span>{text1}</span>
          <span>{text2}</span>
        </StyledLink>
      </StyledContainer>
    )
  }
  return (
    <StyledContainer>
      <StyledParagraph href={url} target="_blank" rel="noreferrer noopener">
        <span>{text1}</span>
        <span>{text2}</span>
      </StyledParagraph>
    </StyledContainer>
  )
}
