import React from "react"
import styled from "styled-components"

const StyledBannerContainer = styled.div`
  width: 100%;

  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
`

export default function Banner({ url, imgSrc, alt }) {
  return (
    <StyledBannerContainer>
      <a href={url} target="_blank" rel="noreferrer noopener">
        <img src={imgSrc} alt={alt} width="100%" height="100%" border="0" />
      </a>
    </StyledBannerContainer>
  )
}
