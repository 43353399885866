import React from "react"
import GatsbyImage from "gatsby-image"
import styled, { css } from "styled-components"
import { useQuery } from "../../hooks"
import { media } from "../../../../styles/media"

const StyledImageLinkContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 300px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;

  @media only screen and (max-width: 768px) {
    max-width: 240px;
    font-size: 14px;
  }
  ${() =>
    media.sp(css`
      max-width: 300px;
    `)}
  .text-container {
    width: 100%;
    position: absolute;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: center;
    gap: 8px;
  }

  p {
    font-size: 0.8em;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    text-align: center;
    gap: 8px;
    transition: background-color 0.3s ease;
    :hover {
      background-color: transparent;
    }
  }
`

export default function RecruitmentImageLink({ textEn, htmlJp, url }) {
  const { recruitBanner } = useQuery()
  return (
    <StyledImageLinkContainer>
      <GatsbyImage
        fluid={recruitBanner.childImageSharp.fluid}
        alt="フランソアグループリクルート特設ページ"
      />
      {/* <div className="text-container">
        <h5>{textEn}</h5>
        <p
          dangerouslySetInnerHTML={{ __html: htmlJp }}
          style={{ textAlign: "center", lineHeight: 1.4 }}
        />
      </div> */}

      <a href={url} target="_blank" rel="noreferrer noopener">
        <span>{textEn}</span>
        <span dangerouslySetInnerHTML={{ __html: htmlJp }} />
      </a>
    </StyledImageLinkContainer>
  )
}
