import React from "react"
import styled, { css } from "styled-components"
import Banner from "./Banner"
import Label from "./Label"
import RecruitmentImageLink from "./RecruitmentImageLink"
import { link } from "../../data.yaml"
import { useQuery } from "../../hooks"
import { media } from "../../../../styles/media"

const StyledContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-around;
  ${() =>
    media.sp(css`
      flex-direction: column;
      align-items: center;
      gap: 32px;
    `)}
`

const StyledEntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 300px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    max-width: 240px;
    font-size: 14px;
  }
  ${() =>
    media.sp(css`
      max-width: 300px;
    `)}
`

const StyledBorder = styled.div`
  display: none;
  ${() =>
    media.sp(css`
      border: 2px solid #dadada;
      width: 32px;
      display: block;
    `)}
`

const StyledBannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export default function ExternalLinks() {
  const { nightFrancoisImage } = useQuery()
  const { recruitment, entry, mynavi, rikunavi, entryHighSchool } = link
  return (
    <StyledContainer>
      <RecruitmentImageLink
        textEn={recruitment.textEn}
        htmlJp={recruitment.htmlJp}
        url={recruitment.url}
      />
      <StyledBorder />
      <StyledEntryContainer>
        <Label
          text1={entry.text1}
          text2={entry.text2}
          url={entry.url}
          imgSrc={nightFrancoisImage.childImageSharp.fluid}
        />
        <StyledBannerContainer>
          <Banner url={rikunavi.url} imgSrc={rikunavi.img} alt={rikunavi.alt} />
          <Banner url={mynavi.url} imgSrc={mynavi.img} alt={mynavi.alt} />
        </StyledBannerContainer>
      </StyledEntryContainer>
      <StyledBorder />
      <StyledEntryContainer>
        <Label
          text1={entryHighSchool.text1}
          text2={entryHighSchool.text2}
          url={entryHighSchool.url}
          imgSrc={nightFrancoisImage.childImageSharp.fluid}
          isLink={true}
        />
      </StyledEntryContainer>
    </StyledContainer>
  )
}
